import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

export default class BaseRoom {
  components = {};

  constructor() {
    this.textureLoader = new THREE.TextureLoader();

    this.dracoLoader = new DRACOLoader();
    this.dracoLoader.setDecoderPath("draco/");

    this.gltfLoader = new GLTFLoader();
    this.gltfLoader.setDRACOLoader(this.dracoLoader);

    this.slots = Object(this.slots_meshes || {}).keys;
  }

  loadMaterials() {
    for (const [name, config] of Object.entries(this.config.textures)) {
      config.map = this.textureLoader.load(config.file);

      for (const [key, value] of Object.entries(config.opts)) {
        config.map[key] = value;
      }
      this.materials[name] = new THREE.MeshBasicMaterial({
        map: config.map,
      });
    }
  }

  getAttributes() {
    let attributes = JSON.parse(JSON.stringify(Alpine.store("defaultValues")));
    this.getConfiguration().forEach((item) => {
      Object.keys(item.attributes).forEach((itemAttr) => {
        attributes[itemAttr] += item.attributes[itemAttr];
      });
    });
    return attributes;
  }

  getConfiguration() {
    let currentComponents = [];

    Object.keys(this.slots_components).forEach((slot) => {
      const component = this.getComponent(slot, this.slots_components[slot]);
      if (component) currentComponents.push(component);
    });

    return currentComponents;
  }

  getComponent(slot, index) {
    return (this.components[slot] || [])[index];
  }

  updateLightMap() {
    this.group.children[0].children.forEach((child) => {
      if (
        this.slots_meshes["floor"].includes(child.name) ||
        this.slots_meshes["floor_boards"].includes(child.name)
      ) {
        child.material.map = this.getLightMapMaterial().map;
      } else {
        child.material = this.getLightMapMaterial();
      }
    });
  }

  selectComponent(slot, index) {
    // TODO: remove incompatible components

    let component = this.getComponent(slot, index);
    if (!component) return;

    // Unslot pre-existing item
    let previous_component = this.getComponent(
      slot,
      this.slots_components[slot],
    );
    if (previous_component && previous_component != component) {
      previous_component.onUnselect();
    }

    this.slots_components[slot] = this.components[slot].indexOf(component);
    this.updateLightMap();

    this.applySlotMaterials();
  }

  unSelectComponent(slot) {
    // TODO: remove incompatible components

    let component = this.getComponent(slot, this.slots_components[slot]);
    if (!component) return;

    delete this.slots_components[slot];
    component.onUnselect();
    this.updateLightMap();
    this.applySlotMaterials();
  }

  applySlotMaterials() {}
}
