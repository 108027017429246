import * as THREE from "three";

export default class DustEffect {
  PARTICLE_COUNT = 5000;
  PARTICLE_SIZE = 0.04;

  particleSystem = undefined;
  particleDirectionX = [];
  particleDirectionY = [];
  particleDirectionZ = [];
  startingLowerLimit = -1;
  upperLimit = 2;

  group = new THREE.Group();
  animationFrameRequestId = null;

  constructor(intensity) {
    intensity = Math.min(Math.max(intensity, 0), 100);

    window.floorSize = new THREE.Vector3();
    window.floor.geometry.computeBoundingBox();
    window.floor.geometry.boundingBox.getSize(floorSize);

    this.setup(intensity);
  }

  setup(intensity) {
    if (intensity == this.currentIntensity) return;

    if (this.animationFrameRequestId !== null) {
      cancelAnimationFrame(this.animationFrameRequestId);
    }

    // Since the intensity has changed, we have to clean up
    let parent = this.group.parent;
    if (parent) {
      window.gu.time.value = 0;
      this.group.parent.remove(this.group);
      this.group = new THREE.Group();
      parent.add(this.group);
    }

    let count = (this.PARTICLE_COUNT / 100) * intensity;
    this.currentIntensity = intensity;
    this.particleDirectionY = new Float32Array(count).fill(1, count / 2);
    this.particleDirectionX = new Float32Array(count).fill(1, count / 2);
    this.particleDirectionZ = new Float32Array(count).fill(1, count / 2);

    const positions = [];
    const particleSizes = new Float32Array(count);
    for (let i = 0; i < count; i++) {
      particleSizes[i] = Math.random() * this.PARTICLE_SIZE + 0.02;
    }

    for (let i = 0; i < count; i++) {
      positions.push(
        (Math.random() - 0.5) * (floorSize.x - 0.2) + 0.3,
        //Math.random() * 1.2 - 1,
        Math.random() * 2.5 - 1,
        (Math.random() - 0.5) * (floorSize.z - 0.2) - 0.3,
      );
    }

    const particlesGeometry = new THREE.BufferGeometry();
    particlesGeometry.setAttribute(
      "sizes",
      new THREE.BufferAttribute(particleSizes, 1),
    );
    particlesGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3),
    );

    let particlesMaterial = new THREE.PointsMaterial({
      transparent: true,
      depthTest: false,
      blending: THREE.AdditiveBlending,
      onBeforeCompile: (shader) => {
        shader.uniforms.time = window.gu.time;
        shader.vertexShader = `
      uniform float time;
      attribute float sizes;
      attribute vec4 shift;
      varying vec3 vColor;

      uniform float size;
      uniform float scale;
      #include <common>
      #include <color_pars_vertex>
      #include <fog_pars_vertex>
      #include <morphtarget_pars_vertex>
      #include <logdepthbuf_pars_vertex>
      #include <clipping_planes_pars_vertex>
      #ifdef USE_POINTS_UV
              varying vec2 vUv;
              uniform mat3 uvTransform;
      #endif
      void main() {
              #ifdef USE_POINTS_UV
                   vUv = ( uvTransform * vec3( uv, 1 ) ).xy;
              #endif
              #include <color_vertex>
              //vColor = vec3(22., 222., 0.) / 255.;
              vColor = vec3(30., 30., 30.) / 255.;
              #include <morphinstance_vertex>
              #include <morphcolor_vertex>
              #include <begin_vertex>
              float t = time;
              float moveT = mod(shift.x + shift.z * t, PI2);
              float moveS = mod(shift.y + shift.z * t, PI2);
              transformed += vec3(cos(moveS) * sin(moveT), cos(moveT), sin(moveS) * sin(moveT)) * shift.w;
              #include <morphtarget_vertex>
              #include <project_vertex>
              gl_PointSize = size * sizes;
              #ifdef USE_SIZEATTENUATION
                   bool isPerspective = isPerspectiveMatrix( projectionMatrix );
                   if ( isPerspective ) gl_PointSize *= ( scale / - mvPosition.z );
              #endif
              #include <logdepthbuf_vertex>
              #include <clipping_planes_vertex>
              #include <worldpos_vertex>
              #include <fog_vertex>
      }
    `;
        shader.fragmentShader = `
      varying vec3 vColor;
      uniform vec3 diffuse;
      uniform float opacity;
      #include <common>
      #include <color_pars_fragment>
      #include <map_particle_pars_fragment>
      #include <alphatest_pars_fragment>
      #include <alphahash_pars_fragment>
      #include <fog_pars_fragment>
      #include <logdepthbuf_pars_fragment>
      #include <clipping_planes_pars_fragment>
      void main() {
              #include <clipping_planes_fragment>
              float d = length(gl_PointCoord.xy - 0.5);
              vec4 diffuseColor = vec4( vColor, smoothstep(0.5, 0.1, d) * 0.5);
              vec3 outgoingLight = vec3( 0.0 );
              #include <logdepthbuf_fragment>
              #include <map_particle_fragment>
              #include <color_fragment>
              #include <alphatest_fragment>
              #include <alphahash_fragment>
              outgoingLight = diffuseColor.rgb;
              #include <opaque_fragment>
              #include <tonemapping_fragment>
              #include <colorspace_fragment>
              #include <fog_fragment>
              #include <premultiplied_alpha_fragment>
      }
    `;
      },
    });

    this.particleSystem = new THREE.Points(
      particlesGeometry,
      particlesMaterial,
    );

    this.group.add(this.particleSystem);
    this.startAnimation();
  }

  startAnimation() {
    const animateFrame = () => {
      this.animate();
      this.animationFrameRequestId = requestAnimationFrame(animateFrame);
    };
    this.animationFrameRequestId = requestAnimationFrame(animateFrame);
  }

  animate() {
    const speed = 0.15;
    const positions = this.particleSystem.geometry.attributes.position.array;

    for (let i = 0, pIndex = 0; i < positions.length; i += 3, pIndex++) {
      positions[i] +=
        this.particleDirectionX[pIndex] * (Math.random() - 0.5) * 0.01 * speed;
      positions[i + 1] +=
        this.particleDirectionY[pIndex] * Math.random() * 0.01 * speed;
      positions[i + 2] +=
        this.particleDirectionZ[pIndex] * (Math.random() - 0.5) * 0.005 * speed;

      if (Math.random() < 0.0005) this.particleDirectionX[pIndex] *= -1;
      if (Math.random() < 0.0005) this.particleDirectionY[pIndex] *= -1;
      if (Math.random() < 0.0005) this.particleDirectionZ[pIndex] *= -1;

      if (positions[i + 1] > this.upperLimit) {
        positions[i + 1] = this.startingLowerLimit;
      } else if (positions[i + 1] < this.startingLowerLimit) {
        positions[i + 1] = this.upperLimit;
      }
    }
    this.particleSystem.geometry.attributes.position.needsUpdate = true;
  }
}
