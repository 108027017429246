import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import Alpine from "alpinejs";
import { registerSW } from "virtual:pwa-register";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";

class HeatDistortionEffect {
  constructor(scene, camera, renderer) {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;

    this.composer = new EffectComposer(renderer);
    this.composer.addPass(new RenderPass(scene, camera));

    const heatHazeShader = {
      uniforms: {
        tDiffuse: { value: null },
        time: { value: 0.0 },
        intensity: { value: 3 },
        resolution: {
          type: "v2",
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float time;
        uniform float intensity;
        uniform vec2 resolution;
        varying vec2 vUv;

        float random(vec2 p) {
          return fract(sin(dot(p.xy, vec2(12.9898,78.233))) * 3758.5453);
        }

        void main() {
          float noiseIntensity = (random(vUv + time) * 0.5 + 0.5) * intensity;
          float dx = noiseIntensity * (0.5 - random(vUv + time)) * intensity * sin(time * 0.1) / resolution.x;
          float dy = noiseIntensity * (0.5 - random(vUv + time)) * intensity * cos(time * 0.1) / resolution.y;
          vec2 distortedUv = vec2(vUv.x + dx, vUv.y + dy);
          vec4 color = texture2D(tDiffuse, distortedUv);
          gl_FragColor = color;
        }
      `,
    };

    // Create the ShaderPass with the custom shader
    this.heatHazePass = new ShaderPass(heatHazeShader);
    this.composer.addPass(this.heatHazePass);

    const fxaaPass = new ShaderPass(FXAAShader);
    const pixelRatio = renderer.getPixelRatio();
    fxaaPass.material.uniforms["resolution"].value.x =
      1 / (window.innerWidth * pixelRatio);
    fxaaPass.material.uniforms["resolution"].value.y =
      1 / (window.innerHeight * pixelRatio);
    this.composer.addPass(fxaaPass);

    window.addEventListener("resize", () => {
      const pixelRatio = this.renderer.getPixelRatio();
      fxaaPass.material.uniforms["resolution"].value.x =
        1 / (window.innerWidth * pixelRatio);
      fxaaPass.material.uniforms["resolution"].value.y =
        1 / (window.innerHeight * pixelRatio);
    });
  }

  setIntensity(newIntensity) {
    this.heatHazePass.uniforms.intensity.value = newIntensity;
  }

  update(deltaTime) {
    this.heatHazePass.uniforms.time.value += deltaTime;
    this.composer.render();
  }
}

const updateSW = registerSW({
  immediate: true,
  onNeedRefresh() {
    if (confirm("Ein Update ist verfügbar. Aktualisieren?")) {
      updateSW(true);
    }
  },
  onOfflineReady() {
    console.log("Offline ready");
  },
});

import KidsRoom from "./rooms/kids_v1.js"; // kids_v0.js
import DustEffect from "./effects/dust_effect.js";
import PoisonEffect from "./effects/poison_effect.js";

/**
 * Base
 */
window.Alpine = Alpine;
Alpine.start();

const canvas = document.querySelector("canvas.webgl");
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.scene = new THREE.Scene();

/**
 * Init room
 */
window.gu = { time: { value: 0 } };
window.room = new KidsRoom();
scene.add(room.group);

window.addEventListener("attributes:change", (values) => {
  let particulates = values.detail.particulates || 0;
  let effluvium = values.detail.effluvium || 0;

  if (window.dustEffect) {
    window.dustEffect.setup(particulates);
  } else {
    window.dustEffect = new DustEffect(particulates);
    scene.add(dustEffect.group);
  }

  if (window.poisonEffect) {
    window.poisonEffect.setup(effluvium);
  } else {
    window.poisonEffect = new PoisonEffect(effluvium);
    scene.add(poisonEffect.group);
  }
});

/**
 * Camera
 */
window.camera = new THREE.PerspectiveCamera(
  25,
  sizes.width / sizes.height,
  0.1,
  100,
);
camera.position.set(...room.config.camera.position);
camera.quaternion.set(...room.config.camera.quaternion);
scene.add(camera);
camera.lookAt(new THREE.Vector3(...room.config.camera.lookAt));

// Controls
window.controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.enabled = false;

controls.maxAzimuthAngle = room.config.controls.maxAzimuthAngle;
controls.minAzimuthAngle = room.config.controls.minAzimuthAngle;
controls.maxPolarAngle = room.config.controls.maxPolarAngle;
controls.maxDistance = room.config.controls.maxDistance;

controls.maxTargetRadius = 1.5;

controls.autoRotateSpeed = 1.5;

controls.cursor.set(...room.config.camera.lookAt);
controls.target.set(...room.config.camera.lookAt);
controls.update();

/**
 * Renderer
 */
window.renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

window.addEventListener("resize", () => {
  setTimeout(() => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.physicallyCorrectLights = true;
    renderer.shadowMap.enabled = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
  }, 200);
});

window.addEventListener("attributes:change", (values) => {
  Alpine.store("values", values.detail);
});

let clock = new THREE.Clock();
const heatDistortionEffect = new HeatDistortionEffect(scene, camera, renderer);
window.tick = () => {
  window.requestAnimationFrame(tick);

  const deltaTime = clock.getDelta();
  heatDistortionEffect.update(deltaTime);

  controls.update();
  renderer.render(scene, camera);
  // heatDistortionEffect.composer.render();
};

tick();
